import { injectable, inject } from 'inversify';
import CcServiceInterface from './cc-service-interface';
import { CC_CONTAINER_TYPES } from '../infrastructure/ioc/cc-container-types';
import CcOrder from '../domain/order/cc-order';
import CcStore from '../domain/store/cc-store';
import CcProduct from '../domain/product/cc-product';
import CcImage from '../domain/image/cc-image';
import CcProductStock from '../domain/productstock/cc-productstock';
import CcCategory from '../domain/category/cc-category';
import CcDiscount from '../domain/discount/cc-discount';
import CcPreparationCost from '../domain/preparationcost/cc-preparationcost';
import CcDayHoursActivityRange from '../domain/dayhoursactivityrange/cc-dayhoursactivityrange';
import CcProvince from '../domain/province/cc-province';
import CcBannerArea from '../domain/banner/cc-banner-area';
import CcBanner from '../domain/banner/cc-banner';
import CcBrand from '../domain/brand/cc-brand';
import CcNutrition from '../domain/nutrition/cc-nutrition';
import CcNutritionnumeric from '../domain/nutritionnumeric/cc-nutritionnumeric';
import CcBannerAreaCode from '../domain/banner/cc-banner-area-code';
import { ApiInterface } from '../infrastructure/api/interfaces/api-interface';
import CcUser from '@/main/webapp/resources/src/vue/domain/user/cc-user';
import { Api } from '@/main/webapp/resources/src/vue/infrastructure/api/api';
import CcProperty from '../domain/user/cc-property';
import CcCustomer from '@/main/webapp/resources/src/vue/domain/user/cc-customer';
import CcProductTag from '@/main/webapp/resources/src/vue/domain/product/cc-product-tag';

@injectable()
class CcService implements CcServiceInterface {
	@inject(CC_CONTAINER_TYPES.ApiInterface)
	private api: ApiInterface;

	static readonly ISO_8601_STRING_PATTERN: string = 'YYYYMMDDHHmmss';
	private BEARER_ACCESS_TOKEN_KEY: string = 'cc_shop_bearer_access_token';
	private BEARER_REFRESH_TOKEN_KEY: string = 'cc_shop_bearer_refresh_token';

	public async getBannerArea (code: CcBannerAreaCode): Promise<CcBannerArea> {
		return Promise.resolve(await this.api.getBannerArea(code)).then((bannerAreaData: any) => {
			let banners: Array<CcBanner> = [];
			bannerAreaData.banners.forEach((bannerData: any) => {
				let image: CcImage = new CcImage(bannerData.image.name);
				image.width = bannerData.image.width;
				image.height = bannerData.image.height;
				banners.push(new CcBanner(bannerData.name, bannerData.alias, bannerData.title, bannerData.url, image, bannerData.published, bannerData.target));
			});
			let bannerArea: CcBannerArea = new CcBannerArea(bannerAreaData.code, bannerAreaData.name, bannerAreaData.position, bannerAreaData.width, bannerAreaData.height, bannerAreaData.displayBannerCount, bannerAreaData.displayBanners);
			bannerArea.banners = banners;
			bannerArea.bannerCount = bannerAreaData.bannerCount;
			bannerArea.creationDate = bannerAreaData.creationDate;
			bannerArea.creationUser = bannerAreaData.creationUser;
			bannerArea.lastModifiedDate = bannerAreaData.lastModifiedDate;
			bannerArea.lastModifiedUser = bannerAreaData.lastModifiedUser;
			return bannerArea;
		});
	}

	public async getCustomCategory (requestParams: any): Promise<Array<CcCategory>> {
		return Promise.resolve(await this.api.getCustomCategory(
			requestParams.path,
			requestParams.nameLike,
			requestParams.noParentCategory,
			requestParams.parentCategoryPath,
			requestParams.nameOrAnchestorCategoryNameLike,
			requestParams.customCategoryFilled
		)).then((categoriesData: any) => {
			let categoryList: Array<CcCategory> = [];
			categoriesData.forEach((categoryData: any) => {
				categoryList.push(this.createCategory(categoryData));
			});
			return categoryList;
		});
	}

	public async getProducts (requestParams: any): Promise<any> {
		return Promise.resolve(await this.api.getProducts(
			requestParams.brandCodes,
			requestParams.productTagIds,
			requestParams.customerDescriptionLike,
			requestParams.customCategoryPaths,
			requestParams.attributes,
			requestParams.size,
			requestParams.page
		)).then((productsData: any) => {
			let response: any = {};
			let products: Array<CcProduct> = [];
			productsData.elements.forEach((productData: any) => {
				products.push(this.createProduct(productData));
			});
			response.products = products;
			response.page = {
				number: productsData.number,
				size: productsData.size,
				totalPages: productsData.totalPages,
				totalElements: productsData.totalElements
			};
			return response;
		});
	}

	public async setProductQuantity (requestParams: any): Promise<CcOrder> {
		return Promise.resolve(await this.api.setProductQuantity(requestParams.productCode, requestParams.quantity)).then((orderData: any) => {
			return this.createOrder(orderData);
		});
	}

	public async getStores (requestParams: any): Promise<Array<CcStore>> {
		return Promise.resolve(await this.api.getStores(
			requestParams.codeLike,
			requestParams.nameLike,
			requestParams.status,
			requestParams.vatNumberLike
		)).then((storesData: any) => {
			let stores: Array<CcStore> = [];
			storesData.forEach((storeData: any) => {
				stores.push(this.createStore(storeData));
			});
			return stores;
		});
	}

	public async getPage (pageAlias: string): Promise<any> {
		return Promise.resolve(await this.api.getPage(pageAlias)).then((pageData: any) => {
			return pageData;
		});
	}

	public async getProductStocks (requestParams: any): Promise<any> {
		return Promise.resolve(await this.api.getProductStocks(
			requestParams.onSale,
			requestParams.productAttributes,
			requestParams.productBrandCodes,
			requestParams.productTagIds,
			requestParams.productCustomerDescriptionLike,
			requestParams.productCustomCategoryPaths,
			requestParams.customSort,
			requestParams.size,
			requestParams.page
		)).then((productStocksData: any) => {
			let response: any = {};
			let productStocks: Array<CcProductStock> = [];
			productStocksData.elements.forEach((productStockData: any) => {
				productStocks.push(this.createProductStock(productStockData));
			});
			response.productStocks = productStocks;
			response.page = {
				number: productStocksData.number,
				size: productStocksData.size,
				totalPages: productStocksData.totalPages,
				totalElements: productStocksData.totalElements
			};
			return response;
		});
	}

	public async getProductStock (alias: string): Promise<CcProductStock> {
		return Promise.resolve(await this.api.getProductStock(alias)).then((productStockData: any) => {
			return this.createProductStock(productStockData);
		});
	}

	public async getSimilarProductStock (alias: string): Promise<Array<CcProductStock>> {
		return Promise.resolve(await this.api.getSimilarProductStock(alias)).then((productStocksData: any) => {
			let productStocks: Array<CcProductStock> = [];
			productStocksData.forEach((productStockData: any) => {
				productStocks.push(this.createProductStock(productStockData));
			});
			return productStocks;
		});
	}

	public async getCurrentOrder (requestParams: any): Promise<CcOrder> {
		return Promise.resolve(await this.api.getCurrentOrder(
			requestParams.codeLike,
			requestParams.nameLike,
			requestParams.status,
			requestParams.year,
			requestParams.storeCode
		)).then((orderData: any) => {
			return this.createOrder(orderData);
		});
	}

	public async getCollectTimes (date: string): Promise<Array<any>> {
		return Promise.resolve(await this.api.getCollectTimes(date)).then((collectTimesData: any) => {
			let collectTimes: Array<any> = [];
			collectTimesData.forEach(function (collectTime: string) {
				collectTimes.push({ code: collectTime.replace(':', ''), label: collectTime });
			});
			return collectTimes;
		});
	}

	public async getUnavailableDates (requestParams: any): Promise<Array<Date>> {
		return Promise.resolve(await this.api.getUnavailableDates(requestParams.date)).then((unavailableDatesData: Array<string>) => {
			let disabledDates: Array<Date> = [];
			unavailableDatesData.forEach(function (unavailableDate: string) {
				let output: Date = new Date();
				if (unavailableDate) {
					const year: number = parseInt(unavailableDate.substr(0, 4));
					const month: number = parseInt(unavailableDate.substr(4, 2)) - 1;
					const day: number = parseInt(unavailableDate.substr(6, 2));
					output = new Date(year, month, day);
				}
				disabledDates.push(output);
			});
			return disabledDates;
		});
	}

	public async changeDeliveryDate (date: string): Promise<CcOrder> {
		return Promise.resolve(await this.api.changeDeliveryDate(date)).then((orderData: any) => {
			return this.createOrder(orderData);
		});
	}

	public async changeCollectDate (date: string): Promise<CcOrder> {
		return Promise.resolve(await this.api.changeCollectDate(date)).then((orderData: any) => {
			return this.createOrder(orderData);
		});
	}

	public async getOrders (requestParams: any): Promise<any> {
		return Promise.resolve(await this.api.getOrders(
			requestParams.number,
			requestParams.status,
			requestParams.type,
			requestParams.sendDate,
			null,
			requestParams.size,
			requestParams.page
		)).then((ordersData: any) => {
			let response: any = {};
			let ordersList: Array<CcOrder> = [];
			if (ordersData.elements) {
				ordersData.elements.forEach((orderData: any) => {
					ordersList.push(this.createOrder(orderData));
				});
			}
			response.orders = ordersList;
			response.page = {
				number: ordersData.number,
				size: ordersData.size,
				totalPages: ordersData.totalPages,
				totalElements: ordersData.totalElements
			};

			return response;
		});
	}

	public async getOrder (requestParams: any): Promise<CcOrder> {
		return Promise.resolve(await this.api.getOrder(
			requestParams.number,
			requestParams.status,
			requestParams.type,
			requestParams.sendDate
		)).then((ordersData: any) => {
			return this.createOrder(ordersData);
		});
	}

	public async getUser (): Promise<CcUser> {
		return Promise.resolve(await this.api.getUser()).then((userData: any) => {
			return this.createUser(userData);
		});
	}

	public async saveOrder (requestParams: any): Promise<boolean> {
		return Promise.resolve(await this.api.saveOrder(requestParams)).then(() => {
			return true;
		});
	}

	public async duplicateOrder (formData: any): Promise<CcOrder> {
		return Promise.resolve(await this.api.duplicateOrder(formData)).then((orderData: any) => {
			return this.createOrder(orderData);
		});
	}

	public async setOrderType (params: any): Promise<CcOrder> {
		return Promise.resolve(await this.api.setOrderType(params)).then((orderData: any) => {
			return this.createOrder(orderData);
		});
	}

	public async setOrderStore (code: string): Promise<CcOrder> {
		return Promise.resolve(await this.api.setOrderStore(code)).then((orderData: any) => {
			return this.createOrder(orderData);
		});
	}

	public async switchCustomer (code: string): Promise<CcOrder> {
		return Promise.resolve(await this.api.switchCustomer(code)).then((orderData: any) => {
			return this.createOrder(orderData);
		});
	}

	public async loadStockSearchAids (requestParams: any): Promise<Array<any>> {
		return Promise.resolve(await this.api.getProductStocks(
			requestParams.onSale,
			requestParams.productAttributes,
			requestParams.productBrandCodes,
			requestParams.productTagIds,
			requestParams.productCustomerDescriptionLike,
			requestParams.productCustomCategoryPaths,
			requestParams.customSort,
			null,
			null
		)).then((stockSearchAidsData: any) => {
			const aids: Array<any> = [];
			if (stockSearchAidsData.elements) {
				stockSearchAidsData.elements.forEach((stockSearchAidData: any) => {
					aids.push({
						label: stockSearchAidData.product.customerDescription,
						value: stockSearchAidData.product.alias
					});
				});
			}
			return aids;
		});
	}

	public async loadProductSearchAids (requestParams: any): Promise<Array<any>> {
		return Promise.resolve(await this.api.getProducts(
			requestParams.brandCodes,
			requestParams.productTagIds,
			requestParams.customerDescriptionLike,
			requestParams.customCategoryPaths,
			requestParams.attributes,
			null,
			null
		)).then((poductSearchAidsData: any) => {
			const aids: Array<any> = [];
			if (poductSearchAidsData.elements) {
				poductSearchAidsData.elements.forEach(function (poductSearchAidData: any) {
					aids.push({
						label: poductSearchAidData.customerDescription,
						value: poductSearchAidData.alias
					});
				});
			}
			return aids;
		});
	}

	public async loadOrdersSearchAids (requestParams: any): Promise<Array<string>> {
		return Promise.resolve(await this.api.getOrders(
			requestParams.number || 0,
			requestParams.status,
			null,
			requestParams.sendDate,
			requestParams.text,
			null,
			null
		)).then((aidsData: any) => {
			const aids: Array<string> = [];
			if (aidsData.elements) {
				aidsData.elements.forEach(function (orderRaw: any) {
					aids.push(orderRaw.number);
				});
			}
			return aids;
		});
	}

	public async passwordChange (form: any): Promise<boolean> {
		return Promise.resolve(await this.api.passwordChange(form)).then(() => {
			return true;
		});
	}

	public async passwordForgotten (form: any): Promise<boolean> {
		return Promise.resolve(await this.api.passwordForgotten(form)).then(() => {
			return true;
		});
	}

	public async passwordExpired (form: any): Promise<boolean> {
		return Promise.resolve(await this.api.passwordExpired(form)).then(() => {
			return true;
		});
	}

	public async registration (form: any): Promise<boolean> {
		return Promise.resolve(await this.api.registration(form)).then(() => {
			return true;
		});
	}

	public async profileUpdate (form: any): Promise<CcUser> {
		return Promise.resolve(await this.api.profileUpdate(form)).then((userData) => {
			let user: CcUser = null;
			if (userData) {
				user = this.createUser(userData);
			}
			return user;
		});
	}

	public async login (payload: any, remember: boolean): Promise<any> {
		let formData = new FormData();
		formData.append('grant_type', 'password');
		formData.append('username', payload.email);
		formData.append('password', payload.password);
		formData.append('scope', 'read write');
		return Promise.resolve(await this.api.login(formData)).then((loginData: any) => {
			this.localStorageSetItem(this.BEARER_ACCESS_TOKEN_KEY, loginData.access_token);
			if (remember) {
				this.localStorageSetItem(this.BEARER_REFRESH_TOKEN_KEY, loginData.refresh_token);
			}
		});
	}

	public async logout (): Promise<any> {
		return Promise.resolve(await this.api.logout()).then(() => {
			this.localStorageRemoveItem(this.BEARER_ACCESS_TOKEN_KEY);
			this.localStorageRemoveItem(this.BEARER_REFRESH_TOKEN_KEY);
			this.api.setDefaultHeaders();
		});
	}

	public async refreshToken (): Promise<any> {
		let formData = new FormData();
		formData.append('grant_type', 'refresh_token');
		formData.append('refresh_token', this.localStorageGetItem(this.BEARER_REFRESH_TOKEN_KEY));
		formData.append('scope', 'read write');
		const api: ApiInterface = new Api();
		return Promise.resolve(await api.refreshToken(formData)).then((refreshTokenData: any) => {
			this.localStorageSetItem(this.BEARER_ACCESS_TOKEN_KEY, refreshTokenData.access_token);
			this.localStorageSetItem(this.BEARER_REFRESH_TOKEN_KEY, refreshTokenData.refresh_token);
		});
	}

	public async getProvinces (): Promise<Array<CcProvince>> {
		return Promise.resolve(await this.api.getProvinces()).then((provincesData: any) => {
			const provinces: Array<CcProvince> = [];
			provincesData.forEach(function (provinceData: any) {
				provinces.push(new CcProvince(provinceData.name, provinceData.code));
			});
			return provinces;
		});
	};

	public async getBrands (): Promise<Array<CcBrand>> {
		return Promise.resolve(await this.api.getBrands()).then((brandsData: any) => {
			const brands: Array<CcBrand> = [];
			brandsData.forEach(function (brandData: any) {
				brands.push(new CcBrand(brandData.code, brandData.name, brandData.position));
			});
			return brands;
		});
	};

	public async getCustomers (vatNumberOrTaxCode: string): Promise<Array<any>> {
		return Promise.resolve(await this.api.getCustomers(vatNumberOrTaxCode)).then((customersData: any) => {
			return customersData.customers;
		});
	};

	public async getSecondaryCustomers (params: any): Promise<any> {
		return Promise.resolve(await this.api.getSecondaryCustomers(params)).then((customersData: any) => {
			return {
				customers: customersData.elements.map((customersData: any) => this.createCustomer(customersData)),
				page: {
					number: customersData.number,
					size: customersData.size,
					totalPages: customersData.totalPages,
					totalElements: customersData.totalElements
				}
			};
		});
	};

	public async sendInformation (formdata: any): Promise<boolean> {
		return Promise.resolve(await this.api.sendInformation(formdata)).then(() => {
			return true;
		});
	};

	public async createShippingDestinations (params: any): Promise<CcUser> {
		params.provinceCode = params.provinceCode?.code || null;
		return Promise.resolve(await this.api.createShippingDestinations(params)).then((userData: any) => {
			return this.createUser(userData);
		});
	};

	public async updatesetShippingDestinations (params: any): Promise<CcUser> {
		params.provinceCode = params.provinceCode?.code || null;
		return Promise.resolve(await this.api.updatesetShippingDestinations(params)).then((userData: any) => {
			return this.createUser(userData);
		});
	};

	public async deleteShippingDestinations (params: any): Promise<boolean> {
		return Promise.resolve(await this.api.deleteShippingDestinations(params)).then(() => {
			return true;
		});
	};

	public async emptycart (): Promise<CcOrder> {
		return Promise.resolve(await this.api.emptycart()).then((orderData: any) => {
			return this.createOrder(orderData);
		});
	}

	public async getTags (): Promise<Array<CcProductTag>> {
		return Promise.resolve(await this.api.getTags()).then((tagsData: Array<any>) => {
			return tagsData.filter((tag: any) => tag.visible).map((tagData: any) => this.createProductTag(tagData));
		});
	}

	public createCategory (categoryData: any): CcCategory {
		let newCategory: CcCategory = null;
		if (categoryData) {
			newCategory = new CcCategory(
				categoryData.childCategoriesCount,
				categoryData.code,
				categoryData.creationDate,
				categoryData.creationUser,
				categoryData.ecrCategoriesCount,
				categoryData.leaf,
				categoryData.level,
				categoryData.name,
				categoryData.path,
				categoryData.root
			);
			newCategory.lastModifiedDate = categoryData.lastModifiedDate;
			newCategory.customCategory = categoryData.customCategory;
		}
		return newCategory;
	}

	public createProduct (productData: any): CcProduct {
		let newProduct: CcProduct = null;
		if (productData) {
			newProduct = new CcProduct(
				productData.code,
				productData.alias,
				productData.description,
				productData.unit,
				productData.contentUnit,
				productData.itemsPerPackage,
				productData.packageType,
				productData.packageOnly
			);
			newProduct.averageWeight = productData.averageWeight;
			newProduct.customerDescription = productData.customerDescription;
			newProduct.customerUnit = productData.customerUnit;
			newProduct.additives = productData.additives ? productData.additives : [];
			newProduct.allergens = productData.allergens ? productData.allergens : [];
			newProduct.alternativeCode = productData.alternativeCode;
			newProduct.attributes = productData.attributes;
			newProduct.biological = productData.biological;
			newProduct.brands = productData.brands ? productData.brands : [];
			newProduct.creationDate = productData.creationDate;
			newProduct.creationUser = productData.creationUser;
			newProduct.features = productData.features ? productData.features : [];
			newProduct.ingredients = productData.ingredients ? productData.ingredients : [];
			newProduct.lactoseFree = productData.lactoseFree;
			newProduct.lastModifiedDate = productData.lastModifiedDate;
			newProduct.lastModifiedUser = productData.lastModifiedUser;
			newProduct.hundredPercentItalian = productData.hundredPercentItalian;
			newProduct.glutenFree = productData.glutenFree;
			newProduct.gtin = productData.gtin;
			newProduct.palmOilFree = productData.palmOilFree;
			newProduct.published = productData.published;
			newProduct.recyclingNotes = productData.recyclingNotes ? productData.recyclingNotes : [];
			newProduct.saltFree = productData.saltFree;
			newProduct.sugarFree = productData.sugarFree;
			newProduct.vegetable = productData.vegetable;
			newProduct.wholemeal = productData.wholemeal;
			newProduct.yeastFree = productData.yeastFree;
			newProduct.caliber = productData.caliber;
			newProduct.contentQuantity = productData.contentQuantity;
			newProduct.fruitAndVegetableCategory = productData.countryOfOrigin;
			newProduct.marketingDescription = productData.marketingDescription ? productData.marketingDescription : '';
			newProduct.otherInformation = productData.otherInformation ? productData.otherInformation : '';
			newProduct.salesDescription = productData.salesDescription ? productData.salesDescription : '';
			newProduct.storage = productData.storage ? productData.storage : '';
			newProduct.ecrCategory = this.createCategory(productData.ecrCategory);
			newProduct.maxDeliveryQuantity = productData.maxDeliveryQuantity;
			newProduct.maxCollectQuantity = productData.maxCollectQuantity;
			newProduct.productTags = productData.productTags.filter((tag: any) => tag.visible).map((tag: any) => this.createProductTag(tag));

			if (productData.numericNutrition != null && productData.numericNutrition.headings.length > 0) {
				newProduct.numericNutrition = new CcNutritionnumeric(productData.numericNutrition.headings, productData.numericNutrition.nutrients, productData.numericNutrition.amounts);
			}
			if (productData.textualNutrition != null && productData.textualNutrition.headings.length > 0) {
				newProduct.textualNutrition = new CcNutrition(productData.textualNutrition.headings, productData.textualNutrition.nutrients, productData.textualNutrition.amounts);
			}
			if (productData.brand !== null) {
				newProduct.brand = new CcBrand(productData.brand.name, productData.brand.code, productData.brand.position);
			}
			if (productData.primaryImage !== null) {
				newProduct.primaryImage = new CcImage(productData.primaryImage.name);
			}
			if (productData.images !== null) {
				productData.images.forEach(function (image: any) {
					newProduct.images.push(new CcImage(image.name));
				});
			}
		}
		return newProduct;
	}

	public createProductStock (productStockData: any): CcProductStock {
		let newProductStock: CcProductStock = null;
		if (productStockData) {
			const newProduct: CcProduct = this.createProduct(productStockData.product);
			newProductStock = new CcProductStock(
				productStockData.availability,
				productStockData.available,
				productStockData.contentUnitListPrice,
				productStockData.contentUnitPrice,
				productStockData.contentUnitSalePrice,
				productStockData.cost,
				productStockData.discount,
				productStockData.listPrice,
				productStockData.salePrice,
				productStockData.onSale,
				productStockData.price,
				newProduct,
				productStockData.quantity
			);
			newProductStock.total = productStockData.total;
			newProductStock.color = productStockData.color;
			newProductStock.label = productStockData.label;
		}
		return newProductStock;
	}

	public createProductTag (productTagData: any): CcProductTag {
		let productTag: CcProductTag = null;
		if (productTagData) {
			productTag = new CcProductTag(productTagData.uniqueId, productTagData.label, productTagData.description, productTagData.color);
		}
		return productTag;
	}

	public createOrder (orderData: any): CcOrder {
		const self: CcService = this;
		let order: CcOrder = null;
		if (orderData) {
			order = new CcOrder(orderData.availableForDelivery, orderData.availableForCollect);
			order.type = orderData.type;
			order.collectDate = orderData.collectDate;
			order.deliveryDate = orderData.deliveryDate;
			order.store = this.createStore(orderData.store);
			order.total = orderData.total;
			order.totalAmount = orderData.totalAmount;
			order.totalDiscount = orderData.totalDiscount;
			order.status = orderData.status;
			order.number = orderData.number;
			order.sendDate = orderData.sendDate;
			orderData.discounts.forEach(function (discount: any) {
				const discountsTemp: CcDiscount = new CcDiscount(discount.description, discount.amount);
				order.discounts.push(discountsTemp);
			});
			orderData.items.forEach(function (productStockRaw: any) {
				const productStockTemp: CcProductStock = self.createProductStock(productStockRaw);
				productStockTemp.available = true;
				order.items.push(productStockTemp);
			});
			if (orderData.preparationCost) {
				order.preparationCost = new CcPreparationCost(
					orderData.preparationCost.code.value,
					orderData.preparationCost.description,
					orderData.preparationCost.startPrice,
					orderData.preparationCost.endPrice
				);
			}
			if (orderData.shippingCost) {
				order.shippingCost = new CcPreparationCost(
					orderData.shippingCost.code.value,
					orderData.shippingCost.description,
					orderData.shippingCost.startPrice,
					orderData.shippingCost.endPrice
				);
			}
			order.shippingInformation = this.createCustomer(orderData.shippingInformation);
			order.customer = this.createCustomer(orderData.customer);
		}
		return order;
	}

	public createStore (storeData: any): CcStore {
		let newStore: CcStore = null;
		if (storeData) {
			const province: CcProvince = new CcProvince(storeData.province.name, storeData.province.code);
			newStore = new CcStore(storeData.name, storeData.code, storeData.status, province, storeData.city, storeData.address, storeData.phone, storeData.fax, storeData.email, storeData.availableForCollect, storeData.availableForDelivery, storeData.notes, storeData.postalCode);

			newStore.creationDate = storeData.creationDate;
			newStore.creationUser = storeData.creationUser;
			newStore.dismissDate = storeData.dismissDate;
			newStore.dismissUser = storeData.dismissUser;
			newStore.lastModifiedDate = storeData.lastModifiedDate;
			newStore.lastModifiedUser = storeData.lastModifiedUser;
			newStore.minimumCollectOrderAmount = storeData.minimumCollectOrderAmount || 0;

			if (storeData.openingTimes) {
				storeData.openingTimes.forEach(function (openingTime: any) {
					newStore.openingTimes.push(new CcDayHoursActivityRange(openingTime.dayOfWeek, openingTime.startTime, openingTime.endTime));
				});
			}
			if (storeData.pickingTimes) {
				storeData.pickingTimes.forEach(function (pickingTime: any) {
					newStore.pickingTimes.push(new CcDayHoursActivityRange(pickingTime.dayOfWeek, pickingTime.startTime, pickingTime.endTime));
				});
			}
		}
		return newStore;
	}

	public createUser (userData: any): CcUser {
		let user: CcUser = null;

		if (userData) {
			user = new CcUser(true);
			user.accountStatus = userData.accountStatus;
			user.accountExpired = userData.accountExpired;
			user.firstName = userData.firstName;
			user.lastName = userData.lastName;
			user.address = userData.address;
			user.birthdate = userData.birthDate;
			user.taxCode = userData.taxCode;
			user.gender = userData.gender;
			user.city = userData.city;
			user.postalCode = userData.postalCode;
			user.mobile = userData.mobile;
			user.phone = userData.phone;
			user.fax = userData.fax;
			user.email = userData.email;
			user.province = userData.province ? new CcProvince(userData.province.name, userData.province.code) : null;
			if (userData.customer) {
				user.customer = this.createCustomer(userData.customer);
			}
			if (userData.currentCustomer) {
				user.routeStore = userData.currentCustomer.route ? this.createStore(userData.currentCustomer.route.store) : null;
				user.deliveryMinimumOrderAmount = userData.currentCustomer.route ? userData.currentCustomer.route.minimumOrderAmount || 0 : 0;
				user.currentCustomer = this.createCustomer(userData.currentCustomer);
			}
			user.properties = (userData.properties || []).map((property: any) => {
				return new CcProperty(property.type, property.code, property.label, property.values);
			});
		} else {
			user = new CcUser(false);
		}

		return	user;
	}

	public createCustomer (customerData: any): CcCustomer {
		let customer: CcCustomer = null;

		if (customerData) {
			const customerProvince = customerData.province ? new CcProvince(customerData.province.name, customerData.province.code) : null;
			customer = new CcCustomer(customerData.code, customerData.name, customerData.address, customerData.city, customerData.postalCode, customerProvince, customerData.status, customerData.phone, customerData.mobile, customerData.email, customerData.notes, customerData.fax, customerData.route?.minimumOrderAmount);
			customer.vatNumber = customerData.vatNumber;
			customer.store = this.createStore(customerData.store);
			customer.hasRoute = !!customerData.route;
			customer.routeStore = this.createStore(customerData.route?.store);
			customer.shippingDestinations = (customerData.shippingDestinations || []).map((shippingDestination: any) => this.createCustomer(shippingDestination));
		}

		return customer;
	}

	private getContextPath () : string {
		const contentPath: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('_context_path')[0]
		);
		return contentPath ? contentPath.content : '';
	}

	public localStorageSetItem (key: string, value: string) {
		localStorage.setItem(this.getContextPath().replace('/', '').concat(`.${key}`), value);
	}

	public localStorageGetItem (key: string): string {
		return localStorage.getItem(this.getContextPath().replace('/', '').concat(`.${key}`));
	}

	public localStorageRemoveItem (key: string) {
		localStorage.removeItem(this.getContextPath().replace('/', '').concat(`.${key}`));
	}
}
export default CcService;
